import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import About from "../views/About.vue";
import Contact from "../views/Contact.vue";
import Home from "../views/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/:catchAll(.*)",
    component: Home
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
