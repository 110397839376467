<template>
  <footer>
    <header>Artur Markowski dev - 2020</header>
    <div class="links">
      <!-- <a
        href="mailto=mail@arturmarkowski.dev?subject=%5Barturmarkowski.dev%5D%20Contact%20request"
        target="'_blank'"
        >Email</a
        > -->
      <a href="https://www.instagram.com/arkitma" target="_blank">
        <font-awesome-icon :icon="['fab', 'instagram']" />
      </a>
      <a href="https://github.com/artir3" target="_blank">
        <font-awesome-icon :icon="['fab', 'github']" />
      </a>
      <a
        href="https://pl.linkedin.com/in/artur-markowski-540725a6"
        target="_blank"
      >
        <font-awesome-icon :icon="['fab', 'linkedin']" />
      </a>
    </div>
  </footer>
</template>

<script lang="ts">
export default {
  name: "Footer"
};
</script>

<style>
footer {
  background-color: #1b262c;
  text-align: center;
  width: 100%;
}

footer > header {
  font-weight: 400;
  font-size: 1em;
  padding: 10px;
  width: 100%;
}

div.links > a {
  font-size: 2h;
  padding: 5px;
  margin-right: 5px;
}
</style>
