<template>
  <div>
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <div class="home">
      <p>I'm Artur Markowski</p>
      <p>
        Software developer
        <br />
        Amator traveler and photographer
        <br />
        Board games player
      </p>
      <p>Welcome on my website</p>
    </div>
  </div>
</template>

<style>
img {
  bottom: auto;
}

div.home {
  float: right;
  line-height: 2.5em;
  width: 50%;
  font-family: Helvetica, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div.home > p {
  font-size: 2em;
}
</style>
