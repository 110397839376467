<template>
  <Navigation />
  <section>
    <router-view />
  </section>
  <Footer />
</template>

<script lang="ts">
import Footer from "./views/Footer.vue";
import Navigation from "./views/Navigation.vue";

export default {
  name: "Home",
  components: { Footer, Navigation }
};
</script>

<style>
section > div > header {
  font-size: 2em;
  line-height: 60px;
  text-align: center;
}
</style>
