<template>
  <div class="navigation">
    <nav>
      <router-link to="/" focus>Home</router-link>
      <router-link to="/about">About</router-link>
      <router-link to="/contact">Contact</router-link>
    </nav>
  </div>
</template>

<script lang="ts">
export default {
  name: "Navigation"
};
</script>

<style>
nav {
  float: right;
  line-height: 60px;
  margin-right: 20px;
}

nav a {
  background: #4e5b64;
  border-radius: 5px;
  color: #bbe1fa;
  font-weight: bold;
  margin-right: 10px;
  padding: 10px;
  font-size: 16px;
  text-decoration: none;
}

nav a.router-link-active {
  color: #0f4c75;
  background: #bbe1fa;
}
</style>
