<template>
  <div class="contact">
    <header>Contact</header>
    <p class="text">
      Thank you for visit my site. <br /><br />If you would like to contact with
      me, you should use one of bellow chanels.
    </p>
    <div class="contact-links">
      <a
        v-bind:href="[
          'mailto:mail@arturmarkowski.dev?subject=Website%20contact'
        ]"
        target="_blank"
      >
        <font-awesome-icon :icon="['fas', 'envelope']" />
        mail@arturmarkowski.dev
      </a>
      <br /><br />
      <a href="https://github.com/artir3" target="_blank">
        <font-awesome-icon :icon="['fab', 'github']" />
        artir3
      </a>
      <br /><br />
      <a href="https://www.instagram.com/arkitma" target="_blank">
        <font-awesome-icon :icon="['fab', 'instagram']" />
        arkitma
      </a>
      <br /><br />

      <a
        href="https://pl.linkedin.com/in/artur-markowski-540725a6"
        target="_blank"
      >
        <font-awesome-icon :icon="['fab', 'linkedin']" />
        Artur Markowski
      </a>
    </div>
  </div>
</template>

<style>
.contact-links > a {
  color: inherit;
  text-decoration: none;
  font-family: Helvetica, serif;
}
</style>
