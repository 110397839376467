<template>
  <div class="about">
    <header>About</header>
    <p>Hi, I'm Artur Markowski.</p>

    <p class="text">
      I&nbsp;have been working as software developer since 2017 and I'm
      a&nbsp;certified AWS Associate Developer since 2019.<br />
    </p>

    <p class="text">
      I&nbsp;have worked for many different companies, but as a&nbsp;programmer,
      I&nbsp;was only in two company.<br /><br />
      In my first job I&nbsp;was a&nbsp;full stack Java developer, where
      I&nbsp;was responsible for the maintenance and development of systems for
      lawyers and for the maintenance of software for a&nbsp;transport company.
      I&nbsp;worked as a&nbsp;backend and frontend devloper three big systems
      for lawyers and they were written in java using Spring, Hibernate, GWT and
      many other frameworks, and technologies. In the software for the transport
      company, I&nbsp;was responsible only for the backend side of the project,
      which was written in Java and Hibernate. Besides the above, I&nbsp;have
      created a&nbsp;few other small projects written in java using Spring.
      <br />
      <br />
      Now, I'm developing applications supporting software management in the
      company of an USA client. The main technologies that I&nbsp;use for work
      are: Java, Hibernate, JavaScript, TypeScript, Angular, ExpressJs, Docker,
      Jenkins.
      <br />
      <br />
      More detailed information you could find on my
      <a
        href="https://pl.linkedin.com/in/artur-markowski-540725a6"
        target="_blank"
      >
        <font-awesome-icon :icon="['fab', 'linkedin']" />
        LinkedIn
      </a>
      or
      <a href="https://github.com/artir3" target="_blank">
        <font-awesome-icon :icon="['fab', 'github']" />
        GitHub </a
      >.
    </p>

    <p class="text">
      I&nbsp;have a&nbsp;wide range of interests, so while working,
      I&nbsp;combine knowledge from various fields of science. That's why
      I&nbsp;have completed two engineering faculties at the Wroclaw University
      of Technology:<br />
    </p>

    <ul class="text">
      <li>
        "Computer science" at the department of computer science and management
        W8,<br />
      </li>
      <li>
        "Municipal power engineering" at the department of Mechanical and Power
        Engineering W9.<br />
      </li>
    </ul>

    <p class="text">
      I&nbsp;was a&nbsp;member of the student science club "FLOW", where
      I&nbsp;was the leader of the group responsible for the design and
      construction of a&nbsp;fast liquid cooler. I&nbsp;finished successfully
      first stage of project where we designed and built device, and
      I&nbsp;design second stage of project - minimalisation and optimization.
    </p>

    <p class="text">
      I'm spending my free time on learning new ideas and frameworks from It.
      I&nbsp;have been learning Spanish for a&nbsp;year. I&nbsp;have been
      playing the battle game Warmachine and hordes for 15 years, including 5
      years in national tournaments. I&nbsp;like to reading business, travel or
      fantasy books. I&nbsp;like traveling and I have hitchhiked or travel by
      car around Poland and Europe on self-organized trips. That's why
      I&nbsp;decided to learn professional photography in 2020.
    </p>
  </div>
</template>
